/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Container, Grid, Typography, Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from './Button'
import Logo from './Logo'

import handImage from '../static/images/hand.png'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    minHeight: '100vh',
  },
  logo: {
    marginTop: theme.spacing(5),
  },
  imageContainer: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(-9),
  },
  image: {
    width: '500px',
    [theme.breakpoints.up('sm')]: {
      width: '700px',
      marginLeft: '-145px',
    },
    height: 'auto',
    marginLeft: '-115px',
    marginTop: '-150px',
    position: 'relative',
    zIndex: 1,
  },
  rectRight: {
    position: 'absolute',
    borderRadius: '25%',
    backgroundColor: '#FD322E',
    top: '-134',
    left: 'calc(100% - 75px)',
    width: '344px',
    height: '344px',
    transform: 'rotate(25.79deg)',
  },
  rectLeft: {
    position: 'absolute',
    borderRadius: '25%',
    backgroundColor: '#FD322E',
    top: '50px',
    left: '-140px',
    width: '196px',
    height: '196px',
    transform: 'rotate(-31.28deg)',
  },
  rectBottom: {
    position: 'absolute',
    borderRadius: '25%',
    backgroundColor: '#FD322E',
    bottom: '-110px',
    width: '482px',
    height: '482px',
    letf: '50px',
    [theme.breakpoints.up('sm')]: {
      width: '700px',
      height: '700px',
      bottom: '-350px',
    },
    transform: 'rotate(52.3deg)',
  },
  main: {
    position: 'relative',
    zIndex: 2,
  },
  textContainer: {
    textAlign: 'center',
  },
  text: {
    color: '#FFF',
    fontSize: '40px',
    fontWeight: 'bold',
  },
  buttonContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
}))

function LangdingPage({ link }) {
  const classes = useStyles()

  return (
    <>
      <Container
        disableGutters
        maxWidth={false}
      >
        <Grid
          container
          justifyContent="center"
          className={classes.container}
        >

          <Grid item xs={12} className={classes.logo}><Logo link={link} /></Grid>

          <Grid item xs={12} className={classes.imageContainer}>
            <img src={handImage} alt="" className={classes.image} />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.main}>
              <Grid
                container
                alignItems="center"
                direction="column"
              >
                <Grid item xs={9} sm={7} md={5} className={classes.textContainer}>
                  <Typography className={classes.text}>One-tap Call Recorder App</Typography>
                </Grid>

                <Grid container item xs={10} sm={8} md={5} className={classes.buttonContainer}>
                  <Button href={link}>Try For free</Button>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <div className={classes.rectRight} />

          <div className={classes.rectLeft} />

          <div className={classes.rectBottom} />

        </Grid>
      </Container>
    </>
  )
}

LangdingPage.propTypes = {
  link: PropTypes.string.isRequired,
}

export default LangdingPage
